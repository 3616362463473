import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby'
import Modal from 'react-bootstrap/Modal';

import PageTemplate from './page-template'
import FormTemplate from 'gatsby-theme-starberry-lomondgroup/src/templates/form-template'
import StaticTemplate from 'gatsby-theme-starberry-lomondgroup/src/templates/static-template'
import TeamTemplate from 'gatsby-theme-starberry-lomondgroup/src/templates/team-template'
import NewsTemplate from 'gatsby-theme-starberry-lomondgroup/src/templates/news-template'
import ReviewsTemplate from 'gatsby-theme-starberry-lomondgroup/src/templates/reviews-template'

import WelcomeImage from "gatsby-theme-starberry-lomondgroup/src/images/festive_opening_hours.png"

export default function DefaultTemplate(props) {

  const [modalShow, setModalShow] = useState(true)
  const [count, setCount] = useState(0);

  var GQLPage = props.data.glstrapi?.page;
  var GQLModules = props.data.glstrapi?.page?.add_components;
  var SiteConfig = props.data.glstrapi?.siteConfig;


  const closeModal = () => {
    setModalShow(false)
  }


  useEffect(() => {
    // Access count value from session storage
    var pageView = sessionStorage.getItem("pageView");
    if (pageView == null) {
      // Initialize page views count
      pageView = 1;
    } else {
      // Increment count
      pageView = Number(pageView) + 1;
    }
    // Update session storage
    sessionStorage.setItem("pageView", pageView);
    setCount(pageView);
  }, []); 

  //console.log("count ===>", count);


  const renderSwitch = (GQLPage, GQLModules) => {

    switch (GQLPage.layout) {
      case 'form_template':
        return <FormTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'banner_with_sidebar':
        return <StaticTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'without_banner_template':
        return <ReviewsTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'static_template':
        return <StaticTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'team_template':
        return <TeamTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'news_template':
        return <NewsTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'landing_template':
        return <PageTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      case 'hardisty_prestige_template':
          return <PageTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
      default:
        return <PageTemplate GQLPage={GQLPage} GQLModules={GQLModules} location={props.location} />;
    }
  }

  return (
    <>
      {renderSwitch(GQLPage, GQLModules)}

      {SiteConfig?.welcome_banner_image?.url && GQLPage?.menu?.slug === "home" && (
          count === 1 &&
          <Modal
              show={modalShow}
              onHide={closeModal}
              size="lg"
              className="welcome_banner"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  {/* <Modal.Title id="contained-modal-title-vcenter">
                      {TeamList.name}
                  </Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                  <img src={SiteConfig?.welcome_banner_image?.url} alt={SiteConfig?.welcome_banner_image?.alternativeText} />
              </Modal.Body>
          </Modal>
        )
      }
    </>
  )

}

export const pageQuery = graphql`
  query DeafultPageQuery($pageId: ID!) {
    glstrapi {

      siteConfig {
        welcome_banner_image {
          alternativeText
          url
        }
      }

      page(id: $pageId) {
        id
        alias
        title
        choose_form
        form_to_email_id
        content
        layout
        publish
        page_class
        imagetransforms
        menu {
          label
          slug
          id
          link_type
          popular_search{
            popular_search{
              title
              content
            }
          }
          parent {
            label
            slug
            link_type
            parent {
              label
              slug
              link_type
              parent {
                label
                slug
                link_type
              }
            }
          }
        }
        banner_section {
          title
          show_search
          show_reviews
          id
          content
          button_cta_2 {
            theme
            target
            label
            id
            custom_link
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
          button_cta_1 {
            theme
            target
            label
            custom_link
            id
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
          banner_image {
            alternativeText
            url
          }
        }
        seo_meta {
          title
          keywords
          description
          id
          image {
            alternativeText
            url
          }
        }

        add_components {
          __typename

          ... on GLSTRAPI_ComponentComponentsStudentHubComponent {
            id
            title
            content
            script_code
          }

          ... on GLSTRAPI_ComponentComponentsSliderComponent {
            id
            show_reviews
            show_property_search
            slider_module {
              id
              title
              content
              image {
                url
                alternativeText
              }
              button_cta {
                label
                theme
                target
                custom_link
                id
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTilesBoxComponent {
            id
            heading_text
            tiles_overlay_color
            number_of_tiles
            tiles_module {
              title
              id
              content
              image {
                url
                alternativeText
              }
              button_cta {
                label
                theme
                target
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsServiceTilesComponent {
            id
            heading_text
            tiles_module {
              title
              content
              image {
                alternativeText
                url
              }
              button_cta {
                theme
                target
                label
                custom_link
                id
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTrustpilotComponent {
            id
            title
            content
            bg_image {
              url
              alternativeText
            }
          }

          ... on GLSTRAPI_ComponentComponentsWhyChooseComponent {
            id
            title
            description
            why_choose_module {
              title
              upload_icon {
                url
                alternativeText
              }
              id
              content
            }
          }

          ... on GLSTRAPI_ComponentComponentsTeamThinkComponent {
            id
            title
            content
            video_reviews {
              id
              Name
              Description
              Video_URL
              Image {
                alternativeText
                url
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsClientLogosComponent {
            id
            title
            client_logos_component {
              client_logo {
                url
                alternativeText
              }
              link
            }
          }

          ... on GLSTRAPI_ComponentComponentsLeadooChatComponent {
            id
            title
            script_code
            content
            bgrd_color:bg_color
          }

          ... on GLSTRAPI_ComponentComponentsHomeTilesComponent {
            home_tiles_block {
              id
              content
              title
              layout_type
              image {
                url
                alternativeText
              }
              button_cta {
                theme
                target
                label
                id
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
          
          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            id
            title
            description
            choose_collection
          }
          
          ... on GLSTRAPI_ComponentComponentsShowStatisticsSection {
            id
            statics_blocks {
              content
              heading
              icon_class
              id
            }
            show
          }
          
          ... on GLSTRAPI_ComponentComponentsShowNewsInsight {
            id
            Title
            show
          }

          ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
            id
            show
            title
            tab_list {
              add_tab
              id
              tab_name
            }
            background_clr:bg_color
          }

          ... on GLSTRAPI_ComponentComponentsVideoSection {
            id
            title
            content
            read_more_content
            embed_video_url
            bg_color
            Button_CTA {
              theme
              target
              label
              custom_link
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            image {
              url
              alternativeText
            }
          }
          ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {
            id
            layout_type
            text_block {
              title
              id
              description
            }
            media_block {
              id
              media_file {
                alternativeText
                url
              }
            }
            Button_CTA {
              theme
              target
              label
              custom_link
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowPropertyJourney {
            id
            show
          }
          ... on GLSTRAPI_ComponentComponentsShowCustomerReviews {
            id
            show
          }
          ... on GLSTRAPI_ComponentComponentsShowMeetTeam {
            id
            show
            title
            team_department
          }
          ... on GLSTRAPI_ComponentComponentsLocalExperience {
            id
            title
            content
            background_color: bg_color
            image_align
            button_cta {
              theme
              target
              custom_link
              id
              label
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            image {
              url
              alternativeText
            }
          }
          
          ... on GLSTRAPI_ComponentComponentsFaq {
            id
            title
            theme
            faq {
              answer
              question
            }
          }
          ... on GLSTRAPI_ComponentComponentsCustomerVideoReviews {
            id
            customer_video_review {
              name
              id
              content
              embed_video_url
              image {
                alternativeText
                url
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsWhatAreYouLookingFor {
            id
            heading
            content
            page_links {
              title
              id
              menu_link {
                custom_link
                id
                label
                target
                link_type
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsPropertyServiceManagement {
            id
            title
            content
            table_section {
              id
              table_header {
                id
                heading
              }
              table_body {
                column_1
                column_2
                column_3
                column_4
              }
              table_bottom_cta {
                column_1_cta
                column_2_cta
                column_3_cta
                column_4_cta
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsValuationSection {
            id
            valuation_box {
              id
              title
              content
              button_cta {
                id
                label
                target
                theme
                custom_link
                menu_item {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsIntroSection {
            id
            link {
              id
              custom_link
              label
              link_type
              target
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                    parent {
                      slug
                    }
                  }
                }
              }
            }
            text_block {
              id
              title
              description
            }
          }
          ... on GLSTRAPI_ComponentComponentsShowRightsideBar {
            id
            show
            show_newsletter
            teams(where: {publish:true}) {
              title
              name
              publish
              slug
              tile_image {
                id
                alternativeText
              }
              imagetransforms
              id
              email
              designation
              mobile_no
            }
            button_cta {
              id
              label
              target
              theme
              custom_link
              menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsReviewSection {
            id
            Reviews {
              Name
              id
              Description
              Video_URL
              Image {
                alternativeText
                url
              }
            }
          }
        }
      }
    
    } 
}`
